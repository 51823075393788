<template>
  <table class="table">
    <thead>
      <tr>
        <th></th>
        <th v-for="(day, index) in days" :key="index">{{ day }}</th>
      </tr>
    </thead>
    <tbody v-if="users.length > 0">
      <tr>
        <td style="text-align: left">
          утреннее ( не комманда / комманда / пастырей / перевод)
        </td>
        <td v-for="(day, index) in days" :key="index">
          <div>
            {{ getDailyPeople(index, 0).length || '-' }} /
            {{ getDailyPeople(index, 1).length || '-' }} /
            {{ getDailyPastors(index).length || '-' }} /
            {{ getDailyTranslater(index).length || '-' }}
          </div>
        </td>
      </tr>
      <tr>
        <td style="text-align: left">
          вечернее ( не комманда / комманда / пастырей / перевод)
        </td>
        <td v-for="(day, index) in days" :key="index">
          <div>
            {{ getNightPeople(index, 0).length || '-' }} /
            {{ getNightPeople(index, 1).length || '-' }} /
            {{ getNightPastors(index).length || '-' }} /
            {{ getNightTranslater(index).length || '-' }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'daily',
  data: function() {
    return {
      days: {},
      users: []
    };
  },
  async mounted() {
    const { body: days } = await this.getData(`/stat/retrts/days`);
    this.days = days;
    // console.log(days);
    const { body: users } = await this.getData(`/stat/usersDaily`);
    // console.log(users);
    this.users = users;
  },
  methods: {
    getDailyPeople(day, commanda = 0) {
      day = Number.parseInt(day);
      const arr = this.users.filter(
        user =>
          Array.isArray(user.days) &&
          user.days.includes(day) &&
          user.commanda === commanda
      );
      return arr;
    },
    getDailyPastors(day) {
      day = Number.parseInt(day);
      const arr = this.users.filter(
        user =>
          Array.isArray(user.days) &&
          user.days.includes(day) &&
          user.ministry_id === 11
      );
      return arr;
    },
    getDailyTranslater(day) {
      day = Number.parseInt(day);
      const arr = this.users.filter(
        user =>
          Array.isArray(user.days) && user.days.includes(day) && user.translater
      );
      return arr;
    },
    getNightPeople(day, commanda = 0) {
      day = Number.parseInt(day);
      console.log(day);

      const arr = this.users.filter(
        user =>
          Array.isArray(user.nights) &&
          user.nights.includes(day) &&
          user.commanda === commanda
      );
      console.log(arr);
      return arr;
    },
    getNightPastors(day) {
      day = Number.parseInt(day);
      const arr = this.users.filter(
        user =>
          Array.isArray(user.nights) &&
          user.nights.includes(day) &&
          user.ministry_id === 11
      );
      return arr;
    },
    getNightTranslater(day) {
      day = Number.parseInt(day);
      const arr = this.users.filter(
        user =>
          Array.isArray(user.nights) &&
          user.days.includes(day) &&
          user.translater
      );
      return arr;
    }
  }
};
</script>
<style lang="scss" scoped>
table {
  & th,
  td {
    border: 1px dotted;
    text-align: center;
  }
}
</style>

<template>
    <div class="byAge row">
        <div class='onerow'>
          <form class='row' @submit.prevent='fetchData'>
            <div class='form-group col-4'>
              <label for="fromAge" class="form-label">Минимальный возраст</label>
              <input type="number" class="form-control" id="fromAge" v-model='minAge'>
            </div>
            <div class='form-group col-4'>
              <label for="toAge" class="form-label label">Максимальный возраст</label>
              <input type="number" class="form-control label" id="toAge" v-model='maxAge'>
            </div>
            <div class='form-group col-4' style="padding-top: 25px">
              <button type="submit" class="btn btn-primary">Показать</button>
            </div>
          </form>
        </div>
        <table class="table col-10 offset-1" v-if='days'>
          <thead>
          <tr>
            <th></th>
            <th v-for="(day, index) in days" :key="index">{{ day }}</th>
          </tr>
          </thead>
          <tbody v-if="userDaily.length">
          <tr>
            <td style="text-align: left">
              утреннее
            </td>
            <td
              v-for="(day, index) in days"
              :key="index"
              style='cursor: pointer'
            >
<!--              <div @click='togle'>-->
<!--                {{ getDailyPeople(index).length || '-' }}-->
<!--              </div>-->
              <div class='details'>
                  <li v-for='person in getDailyPeople(index)' v-bind:key='person.uid'>
                    <a :href="'/user/'+person.uid" target='_blank'>{{person.fullname}} ({{person.age}})</a>
                  </li>
              </div>
            </td>
          </tr>
          <tr>
            <td style="text-align: left">
              вечернее
            </td>
            <td
              v-for="(day, index) in days"
              :key="index"
            >
<!--              <div @click='togle' v-bind:data-day="index" data-type="nights">-->
<!--                  {{ getNightPeople(index).length || '-' }}-->
<!--              </div>-->
              <div class='details'>
                <li v-for='person in getNightPeople(index)' v-bind:key='person.uid'>
                  <a :href="'/user/'+person.uid" target='_blank'>{{person.fullname}} ({{person.age}})</a>
                </li>
              </div>
            </td>
          </tr>
          </tbody>
      </table>
    </div>
</template>

<script>
    export default {
        name: 'Byage',
      data: function() {
          return {
            minAge: 4,
            maxAge: 12,
            days: null,
            userDaily: []
          }
      },
      methods: {
        async fetchData() {
            this.$loading(true);
            try {
              const { body: days } = await this.getData(`/stat/retrts/days`);
              this.days = days;
              const { body: userDaily } = await this.getData(`/stat/usersDaily?from=${this.minAge}&to=${this.maxAge}`);
              console.log(userDaily);
              this.userDaily = userDaily;
            } catch (e) {
              console.error(e);
            }
            this.$loading(false);
          },
        getDailyPeople(day) {
          day = Number.parseInt(day);
          const arr = this.userDaily.filter(
            user =>
              Array.isArray(user.days) &&
              user.days.includes(day)
          );
          return arr;
        },
        getNightPeople(day) {
          day = Number.parseInt(day);
          console.log(day);
          const arr = this.userDaily.filter(
            user =>
              Array.isArray(user.nights) &&
              user.nights.includes(day)
          );
          console.log(arr);
          return arr;
        },
        togle(event) {
          const div = event.target.parentElement.getElementsByClassName('details')[0];
          div.classList.toggle('hidden');
        }
      }
    };
</script>

<style lang="scss" scoped>
  .hidden {
    display: none;
  }
</style>

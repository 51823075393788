<template>
    <div  class="row">
        <div class="col-md-10 offset-md-1">
            <h4>{{title}}</h4>
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>№</th>
                    <th>UID</th>
                    <th>Ф.И.О.</th>
                    <th>Страна</th>
                    <th>Город</th>
                    <th>Церковь</th>
                    <th>служение</th>
                    <th>Прибытие</th>
                    <th>Отъезд</th>
                    <th>Прибыл</th>
                    <th>Оплачено</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="(user, index) in users"
                        v-bind:data-uid="user.uid"
                        class="hover"
                        @click="go"
                        v-bind:key="user.uid"
                >
                    <td>{{index+1}}</td>
                    <td>{{user.uid}}</td>
                    <td>{{user.fullname}}</td>
                    <td>{{user.country}}</td>
                    <td>{{user.city}}</td>
                    <td>{{user.church}}</td>
                    <td>{{user.ministry}}</td>
                    <td>{{earlyDate(user.stDay, user.stNight)}}</td>
                    <td>{{lateDate(user.enDay, user.enNight)}}</td>
                    <td>{{dateFormat(user.dt_arrived, true)}}</td>
                    <td text-align="right">{{user.money>0?user.money:''}}</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>

</template>

<script>
  export default {

    data: function() {
      return {
        users: [],
          title: 'Люди'
      }
    },
    methods: {
      go(event) {
        const uid = event.target.closest('tr').getAttribute('data-uid');
        this.$router.push(`/reg/${uid}`);
      },
      dateFormat(day, time) {
        if (!day) {
          return '';
        }
        const format = time ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';
        return this.$moment(day).format(format);
      },
      earlyDate(dt1, dt2) {
        dt2 = dt2 || dt1;
        dt1 = dt1 || dt2;
        if (!dt1 && !dt2) {
          return '';
        }

        return this.dateFormat(new Date(dt1).getTime() > new Date(dt2).getTime() ? dt2 : dt1);
      },
      lateDate(dt1, dt2) {
        dt2 = dt2 || dt1;
        dt1 = dt1 || dt2;
        if (!dt1 && !dt2) {
          return '';
        }

        return this.dateFormat(new Date(dt1).getTime() > new Date(dt2).getTime() ? dt1 : dt2);
      }
    },

    mounted: async function () {
      const { body } = await this.getData(this.$route.fullPath);

      this.users = body;
      console.log(body);
      if (this.$route.query.title) {
        this.title = this.$route.query.title;
      }
    }
  };

</script>

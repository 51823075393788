<template>
    <div>
        <div class="row">
            <div class="col-2 offset-1">
                <label class="label">Статус</label>
                &nbsp;
                <select v-model="status" class="form-control">
                    <option v-for="status in statuses" :key="status">
                        {{status}}
                    </option>
                </select>
            </div>
            <div class="col-1">
                <label class="label">Язык</label>
                &nbsp;
                <select v-model="language" class="form-control">
                    <option value="-">-</option>
                    <option v-for="language in languages" :key="language" >
                        {{language}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-10 offset-1" style="padding-top: 20px">
            <table class="table">
                <thead>
                <tr>
                    <th>№</th>
                    <th>UID</th>
                    <th title="Сумма"><i class="fa fa-money"></i></th>
                    <th title="Дни"><i class="fa fa-sun-o"></i></th>
                    <th title="Ночи"><i class="fa fa-moon-o"></i></th>
                    <th title="Перевод"><i class="fa fa-headphones"></i></th>
                    <th>ПреРег</th>
<!--                    <th>Рекомендация</th>-->
                    <th>Выставлен</th>
                    <th>Истекает</th>
                    <th>Прибытие</th>
                    <th title="Язык при регистрации">Язык</th>
                    <th>UUID</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="(invoice, index) in invoices"
                        :key="invoice.uuid"
                        :class="invoice.transactionStatus"
                >
                    <td>{{index+1}}</td>
                    <td>
                        <router-link :to="'/user/'+invoice.uid" target="_blank">{{invoice.uid}}</router-link>
                    </td>
                    <td>
                        <i v-if="invoice.bage" class="fa fa-id-card"></i>
                        {{invoice.amount}}
                    </td>
                    <td>{{invoice.days}}</td>
                    <td>{{invoice.nights}}</td>
                    <td>{{invoice.interpreters}}</td>
                    <td><span v-if="invoice.prereg">✔</span></td>
<!--                    <td>{{invoice.recommendation}}</td>-->
                    <td :title="$moment(invoice.createdDate).format('DD.MM.YYYY HH:mm')">{{$moment(invoice.createdDate).format('DD.MM.YYYY')}}</td>
                    <td><span v-if="invoice.expired" :title="$moment(invoice.expired).format('DD.MM.YYYY HH:mm')">{{$moment(invoice.expired).format('DD.MM.YYYY')}}</span></td>
                    <td>
                                <span v-if="invoice.dt_arrived">
                                    {{$moment(invoice.dt_arrived).format('DD.MM.YYYY HH:mm')}}
                                </span>
                    </td>
                    <td>{{invoice.language}}</td>
                    <td v-html="invoice.uuid"></td>
                </tr>
                </tbody>

            </table>
        </div>

    </div>

</template>

<script>
  export default {
    data: function () {
      return {
        data: [],
        status: '-',
        statuses: [],
        language: '-',
        languages: []
      }
    },

    mounted: async function () {
      const { body } = await this.getData('/stat/invoices');
      this.statuses = [];
      const obj = {};
      const obj1 = {};
      this.data = body.map(item => {
        item.language = item.language.toLowerCase();
        if (!item.transactionStatus) {
          item.transactionStatus = '-';
        }
        if (!item.language) {
          item.language = '-';
        }
        obj[item.transactionStatus] = 1;
        obj1[item.language] = 1;
        return item;
      });
      this.statuses = Object.keys(obj).sort();
      this.languages = Object.keys(obj1).sort();
    },
    computed: {
      invoices() {
        let result = this.data;
        if (this.status !== '-') {
          result = result.filter(item => item.transactionStatus === this.status);
        }
        if (this.language !== '-') {
          result = result.filter(item => item.language === this.language);
        }
        return result;
      }
    }
  }
</script>

<style lang="scss" scoped>
    .declined {
        background-color: #f0f0f0;
    }

    .approved {
        background-color: #dfd;
    }

    .pending {
        background-color: #FFF9B9;
    }
</style>

<template>
    <div class="" >
        <div class="row d-print-none" >
            <div class="col-md-3 offset-md-1">
                <label for="gender">Пол</label>
                <select v-model="gender" id="gender" class="form-control">
                    <option value="-">Любой</option>
                    <option value='m'>Братья</option>
                    <option value='w'>Сестры</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="baza">База</label>
                <select v-model="baza" id="baza" class="form-control">
                    <option value="0">Все</option>
                    <option
                            v-for="bazaId in Object.keys(bazas)"
                            v-bind:value="bazaId"
                            v-bind:key="bazaId"
                    >
                        {{bazas[bazaId]}}
                    </option>
                </select>
            </div>
            <div class="col-md-2">
                <div class="form-check" style="padding-top: 37px">
                    <input type="checkbox" v-model="onlyArrived" class="form-check-input" id="onlyArrived"/>
                    <label for="onlyArrived" class="form-check-label">Только прибывшие</label>
                </div>
            </div>
            <div class="col-md-2" style="margin-top: 15px">&nbsp;
                <button class="btn btn-secondary" @click="print" >Печать</button>
            </div>
        </div>
        <div class="row" v-if="Object.keys(nights).length > 0" style="padding-top: 10px" >
            <div class="col-md-10 offset-md-1">
                Данные по поселению <span style="font-weight: bold;color: navy; font-size: 1.1rem">{{bazas[baza]}}</span>  на <b>{{now}}</b>

            </div>
            <table class="table col-md-10 offset-md-1">
                <thead>
                <tr>
                    <th>№№</th>
                    <th>UID</th>
                    <th >Ф.И.О.</th>
                    <th
                            v-for="night in nights"
                            v-bind:key="night"
                            class="date-wrapper"
                    >
                        <span class="date">
                            {{night}}
                        </span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="(item, index) in poselenie"
                        v-bind:key="item.id"
                        class="hover"
                        @click="selected(item.uid)"
                >

                    <td>{{index+1}}</td>
                    <td>{{item.uid}}</td>
                    <td>{{item.fullname}}</td>
                    <td
                            v-for="d in nightCount"
                            v-bind:key="d"
                            class="baza"
                    >
                        {{userBaza(item, d)}}
                    </td>
                </tr>
                <tr>
                    <th colspan="3">Всего</th>
                    <th
                            v-for="h in nightCount"
                            v-bind:key="h"
                            style="text-align: center"
                    >
                        {{peopleForDay(h) ||'-'}}
                    </th>
                </tr>
                </tbody>

            </table>

        </div>
    </div>

</template>

<style scoped>
    .date{
        display:inline-block;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        margin-top: 10px;
        margin-bottom: 10px;
    }
    table thead tr th.date-wrapper{
        padding: 30px 0 30px 0;

        text-align: center;
    }
    table  th {
        vertical-align: middle;
        border-left: 1px dotted;

    }
    table td{
        border-left: 1px dotted;
    }
    td.baza{
        /*font-size: 0.9rem;*/
        padding: 1px;
        margin: 0;
        text-align: center;
        vertical-align: middle;
        font-size: 14px;
    }

</style>

<script>
    export default {
      data: function() {
        return {
          bazas: {},
          gender: '-',
          onlyArrived: true,
          baza: 0,
          now: null,
          nightCount: 0,
          accommodations: [],
          nights: {}
        }
      },
      computed: {
        poselenie: function() {
          let arr = this.accommodataions;
          if (this.onlyArrived) {
            arr = arr.filter(item => item.dt_arrived);
          }
          if (Number.parseInt(this.baza)) {
            arr = arr.filter(item => item.bazas[this.baza]);
          }
          if (this.gender !== '-') {
            arr = arr.filter(item => item.sex === this.gender);
          }
          return arr;
        }

      },
      methods: {
        selected(uid) {
          let routeData = this.$router.resolve({ path: `/reg/${uid}` });
          window.open(routeData.href, '_blank');
          // this.$router.push(`/reg/${uid}`);
        },
        peopleForDay(day) { // считаем количество людей на базе в этот день
          // console.log(this.poselenie);
          let result = 0;
          const baza = Number.parseInt(this.baza);
          this.poselenie.forEach(item => {
            if (item.poselenie[day]) {
              if (baza > 0) {
                if (item.poselenie[day] === baza) {
                  result++;
                }
              } else {
                result++;
              }
            }
          });
          return result;
        },
        userBaza(item, day) {
          if (!item.poselenie) {
            console.warn(item);
            return '';
          }
          // console.log(item);
          const userBaza = item.poselenie[day];
          if (userBaza) {
            if (this.baza > 0) {
              return Number.parseInt(this.baza) === userBaza ? '+' : '';
            }
            return this.bazas[userBaza];
          }
          return '';
        },
        print() {
          window.print();
        }
      },
      mounted: async function () {
        // console.log('mounted');
        const { body: { accommodations, bazas, now } } = await this.getData(`/stat/accommodations`);
        const { body: days } = await this.getData(`/stat/retrts/days`);
        // delete days[Object.keys(days).length];
        this.nightCount = Object.keys(days).length;
        this.nights = days;
        this.bazas = bazas;
        this.now = this.$moment(now).format('DD.MM.YYYY HH:mm');// @todo часовой пояс!
        // console.log(accommodations);
        accommodations.forEach(item => {
          const bazas = {};
          const { poselenie } = item;
          Object.keys(poselenie).forEach(day => {
            bazas[poselenie[day]] = true;
          });
          item.bazas = bazas;
        });
        this.accommodataions = accommodations;
      }
    }

</script>

<template>
    <div class="row">
        <div class="col-md-8 offset-md-2 row">
            <div class="col-10 offset-1">
                <h4>Служение перевода</h4>
            </div>

            <div class="col-5">
                <label class="label">Страны</label>
                <v-select v-model="selectedCountries" multiple :options="countries"></v-select>
            </div>
            <div class="col-7" style="margin-bottom: 10px">
                <label class="label">Дни</label>
                <div>
                    <div class="day"
                         :key="index"
                         :class="{active: selectedDays.includes(index)}"
                          v-for="(day, index) in days"
                         @click="selectDay(index)"
                         :title="$moment(stdate).add(index-1, 'days').format('DD.MM.YYYY')"
                    >
                        {{day}}
                    </div>

                </div>
            </div>
            <div class="col-3 form-check" style="margin-bottom: 10px">
                <input type="checkbox" class="form-check-input" id="onlyArrived" v-model="arrived">
                <label for="onlyArrived" class="form-check-label">Только прибывшие</label>
            </div>

            <table class="table table-striped">
                <thead>
                <tr>
                    <th>№</th>
                    <th>UID</th>
                    <th>Ф.И.О.</th>
                    <th>Страна</th>
                    <th>Город</th>
                    <th>Ожидается<br>прибытие</th>
                    <th>Прибытие</th>
                    <th>Утро</th>
                    <th>Вечер</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="(item, index) in users"
                        v-bind:data-uid="item.uid"
                        class="hover"
                        v-bind:key="item.uid"
                >
                    <td>{{index+1}}</td>
                    <td><a target="_blank" :href="'/reg/'+item.uid">{{item.uid}}</a></td>
                    <td>{{item.fullname}}</td>
                    <td>{{item.country}}</td>
                    <td>{{item.city}}</td>
                    <td>{{$moment(item.stDay).format('DD.MM.YYYY')}}</td>
                    <td>{{item.dt_arrived ? $moment(item)
                      .format('DD.MM.YYYY') : '-'}}</td>
                    <td>{{item.interpreters}}</td>
                    <td>{{item.interpreters2}}</td>
                </tr>
                </tbody>

            </table>
        </div>

    </div>

</template>

<script>
  import vSelect from 'vue-select';

  export default {
    data: function() {
      return {
        data: [],
        countries: [],
        selectedCountries: [],
        arrived: true,
        days: {},
        selectedDays: [],
        stdate: null
      }
    },
    computed: {
      users() {
        let arr = this.data;
        if (this.arrived) {
          arr = arr.filter(item => item.dt_arrived);
        }
        if (this.selectedCountries.length > 0) {
          arr = arr.filter(item => this.selectedCountries.includes(item.country));
        }
        if (this.selectedDays.length > 0) {
          arr = arr.filter(item => {
            const days = item.interpreters.split(/;/);
            return this.selectedDays.some(sitem => days.includes(sitem));
          });
        }
        return arr;
      }
    },
    methods: {
      selectDay(index) {
        const i = this.selectedDays.indexOf(index);
        if (i === -1) {
          this.selectedDays.push(index);
        } else {
          this.selectedDays.splice(i, 1);
        }
      }

    },

    async mounted() {
      this.$loading(true);
      const { body } = await this.getData(`/interpreters/stat`);
      this.countries = Array.from(new Set(body.map(item => item.country)).keys());
      this.data = body.map((item) => {
        if (item.stDay && item.stNight) {
          const d = new Date(item.stDay);
          const n = new Date(item.stNight);
          if (n.getTime() < d.getTime()) {
            item.stDay = item.stNight;
          }
        }
        if (!item.stDay && item.stNight) {
          item.stDay = item.stNight;
        }
        return item;
      });
      console.log(body);
      const { body: { stdate, dayCount } } = await this.getData('/stat/retrts/dayCount');
      const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
      this.days = {};
      for (let cc = 1; cc <= dayCount; cc++) {
        this.days[cc] = daysOfWeek[this.$moment(stdate).add(cc - 1, 'days').weekday()];
      }
      this.stdate = stdate;
      this.$loading(false);
    },
    components: {
      vSelect
    }
  }

</script>

<style lang="scss" scoped>
    @import "../../variables";
    .day{
        display: inline-block;
        width: 50px;
        height: 50px;
        text-align: center;
        border: 1px #ddd dotted;
        border-radius: 50px;
        padding-top: 13px;
        cursor: pointer;
    }

    .day.active {
        background-color: $active !important;
        font-weight: bold;
    }
</style>

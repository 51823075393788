<template>
    <div class="row">
        <div class="col-md-6 offset-md-3">
            <h4>Страны</h4>

            <table class="table table-striped">
                <thead>
                <tr>
                    <th>№№</th>
                    <th>Страна</th>
                    <th>Людей</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="(country, index) in countries"
                        v-bind:data-id="country.country_id"
                        :key="country.country_id"
                        @click="go"
                        class="hover"
                >
                    <td>{{index+1}}</td>
                    <td>{{country.country}}</td>
                    <td>{{country.cc}}</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>

</template>

<script>
  export default {
    data: function() {
      return {
        countries: []
      }
    },
    methods: {
      go(event) {
        const cid = event.target.closest('tr').getAttribute('data-id');
        this.$router.push(`/stat/cities?country_id=${cid}`);
      }
    },

    mounted: async function () {
      const { body } = await this.getData(`stat/countries`);
      this.countries = body;
    }

  };

</script>

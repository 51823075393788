<template>
    <div class="row" >
        <div class="col-md-6 offset-md-3">
            <h4>Церкви</h4>
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>№</th>
                    <th>Церковь</th>
                    <th>Страна</th>
                    <th>Город</th>
                    <th>Людей</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="(church, index) in churches"
                        v-bind:data-id="church.church_id"
                        @click="go"
                        class="hover"
                        v-bind:key="church.id"
                >
                    <td>{{index+1}}</td>
                    <td>{{church.church}}</td>
                    <td>{{church.country}}</td>
                    <td>{{church.city}}</td>
                    <td align="right">{{church.cc}}</td>
                </tr>
                </tbody>
            </table>

        </div>
    </div>

</template>

<script>
    export default {
      data: function() {
        return {
          churches: []
        }
      },
      methods: {
        go(event) {
          const cid = event.target.closest('tr').getAttribute('data-id');
          this.$router.push(`/stat/users?church_id=${cid}`);
        }
      },

      mounted: async function () {
        const { body } = await this.getData(`/stat/churches`);
        this.churches = body;
      }
    }

</script>

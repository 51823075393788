<template>
    <form class="row" @submit.prevent="request">
        <div class="onerow card ">
            <div class="card-header">Выдача приемников</div>
            <div class="card-body" v-if="error">
                <div class="alert alert-danger">
                    {{error}}
                </div>
                <div v-if="interpreters !== null">
                    <div class="day"
                         :key="index"
                         v-for="(day, index) in days"
                         :class="{curday: curday == index, active: isActive(index)}"
                    >
                        {{day}}
                    </div>
                </div>
              <div v-if="interpreters2 !== null">
                <div class="day"
                     :key="index"
                     v-for="(day, index) in days"
                     :class="{curday: curday == index, active: isActive2(index)}"
                >
                  {{day}}
                </div>
              </div>

            </div>
            <div class="card-body allert alert-success" v-else-if="success">
                Выдано!
            </div>

            <div class="card-body row" v-else>
                <div class="onerow3">
                    <label class="label" for="uid">UID</label>
                    <input id="uid" class="form-control" type="number" ref="uid"
                           v-model.number="uid"/>
                </div>
                <div class="onerow3">
                    <label class="label" for="rnum">Инв.№</label>
                    <input id="rnum" class="form-control" type="number"
                           v-model.number="rnum"/>
                </div>
                <div class="onerow3">
                    <label class="lang" for="rnum">Язык</label>
                    <select id="lang" class="form-control" v-model="lang">
                        <option v-for="key in Object.keys(languages)" :value="key" :key="key">{{languages[key]}}
                        </option>

                    </select>
                </div>

            </div>

            <div class="card-footer">
                <button class="btn btn-primary" type="reset" v-if="error" @click="clearError">Очистить</button>
                <button class="btn btn-primary" type="reset" v-else-if="success" @click="clearSuccess">Продолжить
                </button>

                <button v-else class="btn btn-primary" type="submit" :disabled="!isReady()">Запрос</button>
            </div>

        </div>
    </form>
</template>

<script>
  import languages from '@/components/languages';

  export default {
    name: 'giveOut',
    data: function () {
      return {
        uid: null,
        rnum: null,
        lang: null,
        error: null,
        days: {},
        interpreters: null,
        interpreters2: null,
        curday: null,
        success: null
      }
    },
    methods: {
      request: async function () {
        if (this.error) {
          return false;
        }
        this.$loading(true);
        const data = {
          uid: this.uid,
          rnum: this.rnum,
          lang: this.lang
        };
        try {
          await this.postData('/interpreters/giveOut', data);
          this.$loading(false);
          this.success = true;
        } catch (e) {
          this.$loading(false);
          console.error(e);
          if (e.data && typeof e.data === 'object') {
            ({
              interpreters: this.interpreters,
              interpreters2: this.interpreters2,
              curday: this.curday
            } = e.data);
            this.error = 'Сегодня выдать нельзя';
            return;
          }

          this.error = e.body;
        }
      },
      isReady() {
        return Number.isFinite(Number.parseInt(this.uid)) && Number.isFinite(Number.parseInt(this.rnum));
      },
      clearError() {
        this.error = null;
        this.uid = null;
        this.interpreters = null;
        this.interpreters2 = null;
        this.curday = null;
        if (this.$refs.uid) {
          this.$refs.uid.focus();
        }
      },
      clearSuccess() {
        this.clearError();
        this.rnum = null;
        this.interpreters = null;
        this.interpreters2 = null;
        this.lang = null;
        this.success = null;
      },
      isActive(day) {
        if (!this.interpreters.split) {
          return false;
        }
        return this.interpreters.split(/;/).includes(day);
      },
      isActive2(day) {
        if (!this.interpreters2.split) {
          return false;
        }
        return this.interpreters2.split(/;/).includes(day);
      }
    },
    async mounted() {
      const { body: { stdate, dayCount } } = await this.getData('/stat/retrts/dayCount');
      const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
      this.days = {};
      for (let cc = 1; cc <= dayCount; cc++) {
        this.days[cc] = daysOfWeek[this.$moment(stdate).add(cc - 1, 'days').weekday()];
      }
      this.clearError();
    },
    computed: {
      languages: () => languages
    }
  }
</script>

<style scoped lang="scss">
    @import '~bootstrap/scss/bootstrap.scss';

    .onerow3 {
        @extend .col-6;
        @extend .col-sm-6;
        @extend .col-md-6;
        @extend .col-lg-4;
        @extend .form-group;
        padding-top: 5px;
    }

    @import "../../variables";
    .day {
        display: inline-block;
        width: 30px;
        height: 30px;
        text-align: center;
        border: 1px #ddd dotted;
        border-radius: 30px;
        padding-top: 4px;
        cursor: pointer;
    }

    .day.active {
        background-color: $active !important;
    }

    .curday {
        border: 1px #000 solid;
        font-weight: bold;
    }
</style>

<template>
    <form class="row" @submit.prevent="request">
        <div class="onerow card ">
            <div class="card-header">Возврат приемников</div>
            <div class="card-body" v-if="error">
                <div class="alert alert-danger">
                    {{error}}
                </div>
            </div>
            <div class="card-body allert alert-success" v-else-if="success">
                Возвращено!
            </div>

            <div class="card-body row" v-else>
                <div class="onerow">
                    <label class="label" for="rnum">Инв.№</label>
                    <input id="rnum" ref="rnum" class="form-control" type="number"
                           v-model.number="rnum"/>
                </div>
            </div>

            <div class="card-footer">
                <button class="btn btn-primary" type="reset" v-if="error" @click="clearError">Очистить</button>
                <button class="btn btn-primary" type="reset" v-else-if="success" @click="clearSuccess">Продолжить
                </button>

                <button v-else class="btn btn-primary" type="submit" :disabled="!isReady()">Запрос</button>
            </div>

        </div>
    </form>
</template>

<script>
  export default {
    name: 'giveIn',
    data: function () {
      return {
        rnum: null,
        error: null,
        success: null
      }
    },
    methods: {
      request: async function () {
        console.log('here');
        if (this.error) {
          return false;
        }
        this.$loading(true);
        try {
          await this.getData(`/interpreters/giveIn/${this.rnum}`);
          this.$loading(false);
          this.success = true;
        } catch (e) {
          this.$loading(false);
          console.error(e);
          this.error = e.body;
        }
      },
      isReady() {
        return Number.isFinite(Number.parseInt(this.rnum));
      },
      clearError() {
        this.error = null;
        this.rnum = null;
      },
      clearSuccess() {
        this.clearError();
        this.rnum = null;
        this.success = null;
      }
    },
    async mounted() {
      this.clearError();
      this.$refs.rnum.focus();
    }
  }
</script>

<style scoped lang="scss">

</style>

<template>
    <div class="row">
        <div class="col-10 offset-1 row">
            <div class="col-3 ">
                <label class="label">Дата начала</label>
                <flat-pickr
                        v-model="from"
                        class="input form-control form-control-sm"
                        :config="dateTimePicker"
                ></flat-pickr>
            </div>
            <div class="col-3 ">
                <label class="label">Дата окончания</label>
                <flat-pickr
                        v-model="to"
                        class="input form-control form-control-sm"
                        :config="dateTimePicker"
                ></flat-pickr>
            </div>
            <div class="col-4 form-group">
                <label class="label">Интервал</label>
                <select class="form-control" v-model="interval">
                    <option value="10">10 минут</option>
                    <option value="15">15 минут</option>
                    <option value="30">30 минут</option>
                    <option value="60">60 минут</option>
                    <option value="120">2 часа</option>
                </select>
            </div>
            <div class="col-2  form-group" style="vertical-align: bottom">
                <button class="btn btn-primary" @click="refresh" style="margin-top: 28px">Обновить</button>
            </div>
        </div>
        <div class="col-10 offset-1">
            <canvas ref="canvas"></canvas>
        </div>

    </div>
</template>

<script>
  import { Line } from 'vue-chartjs';
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';
  import { Russian } from 'flatpickr/dist/l10n/ru.js';
  export default {
    extends: Line,
    data: function () {
        return {
          interval: 30,
          options: {
            responsive: true
            // maintainAspectRatio: false
          },
          dateTimePicker: {
            enableTime: false,
            locale: Russian,
            time_24hr: true
          },
          from: new Date(),
          to: new Date()
        }
    },
    name: 'queue',
    methods: {
      async refresh() {
        const uri = `/stat/queue/${this.interval}?from=${this.$moment(this.from).format('YYYY-MM-DD')}&to=${this.$moment(this.to).format('YYYY-MM-DD')}`;
        console.log(uri);
        const { body } = await this.getData(uri);
        const labels = [];
        const data = [];
        console.log(this.$moment.tz.guess());
        body.forEach(item => {
          labels.push(this.$moment.tz(item.dt, 'Europe/Kiev').format('DD.MM HH:mm'));
          data.push(item.cc);
        });
        console.log(labels);
        console.log(body);
        this.renderChart({
          labels,
          datasets: [
            {
              label: 'Загрузка очереди',
              backgroundColor: '#f87979',
              data
            }
          ]
        }, this.options);
      }
    },
    mounted() {
      // this.from = '2020-01-06';
      // this.to = '2020-01-06';
      // this.refresh();
    },
    components: {
      flatPickr
    }
  }
</script>

<style lang="scss" scoped>
</style>

<template>
    <div class="row">
        <div class="col-10 offset-1">
            <table class="table stripped">
                <thead>
                    <tr>
                        <th>№№</th>
                        <th>Ф.И.О. (UID)</th>
                        <th>№</th>
                        <th>Язык</th>
                        <th>Выдал</th>
                        <th>Дата выдачи</th>
                        <th>Дни</th>
                        <th>Страна</th>
                        <th>Телефон</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in rows" :key="row.id">
                        <td>{{index+1}}</td>
                        <td>
                            <router-link :to="'/user/'+row.uid" target="_blank">{{row.fullname}} </router-link>
                            <router-link :to="'/reg/'+row.uid" target="_blank">({{row.uid}})</router-link>
                        </td>
                        <td>{{row.nn}}</td>
                        <td>{{row.lang}}</td>
                        <td>{{row.adminName}}</td>
                        <td :title="$moment(row.issueDate).format('DD.MM.YYYY HH:mm')">{{$moment(row.issueDate).format('DD.MM.YYYY')}}</td>
                        <td>{{row.lastInterpreters}}</td>
                        <td>{{row.country}}</td>
                        <td>{{row.mobile}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'issued',
    data: function () {
        return {
          rows: []
        }
    },
    async mounted() {
      const { body } = await this.getData('/interpreters/issued');
      this.rows = body;
      console.log(body);
    }
  }
</script>

<style scoped lang="scss">

</style>

<template>
    <div class="row">
        <div class="col-md-6 offset-md-3">
            <h4>Города</h4>
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>№№</th>
                    <th>Страна</th>
                    <th>Город</th>
                    <th>Людей</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="(city, index) in cities"
                        v-bind:data-id="city.city_id"
                        @click="goPeoples"
                        class="hover"
                        v-bind:key="city.city_id"
                >
                    <td>{{index+1}}</td>
                    <td>{{city.country}}</td>
                    <td>{{city.city}}</td>
                    <td>{{city.cc}}</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>

</template>

<script>
    export default {
      data: function() {
        return {
          cities: []
        }
      },
      methods: {
        goPeoples(event) {
          const cid = event.target.closest('tr').getAttribute('data-id');
          this.$router.push(`/stat/users?city_id=${cid}`);
        }
      },

      mounted: async function () {
        console.log(this.$route);
        const { body } = await this.getData(`${this.$route.fullPath}`);
        this.cities = body;
        console.log(body);
      }
    }

</script>

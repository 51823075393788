<template>
    <div>
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <h4>
                    <i @click="load"
                       style="cursor: pointer"
                       class="fa fa-refresh "></i>
                    Данные на {{$moment(now).zone(new Date().getTimezoneOffset()).format('DD.MM.YYYY HH:mm')}}
                </h4>
                <div class="row">
                    <div class="col-md-4">
                        <ul>
                            <li><router-link to="/stat/countries">Стран:</router-link> {{countryCount}}</li>
                            <li><router-link to="/stat/cities">Городов:</router-link> {{cityCount}}</li>
                            <li><router-link to="/stat/churches">Церквей:</router-link> {{churchCount}}</li>
                            <li><router-link to="/stat/users">Человек</router-link> / экспресс: {{peopleCount}} / {{expressPercent||''}}%</li>
                            <li><router-link to="/stat/invoices">Экспресс платежей:</router-link> {{expressCount|| 0 }}</li>
                            <li><router-link to="/stat/users?ministry_id=11&title=Пастыря">Пастырей:</router-link> {{pastorCount}}</li>
                        </ul>
                    </div>
                  <!--
                                      <div class="col-md-4">
                                          <ul>
                                            <li><router-link to="/stat/commanda">Команда: {{commandaCount}}</router-link></li>
                                             <li><router-link to="/stat/accommodataions">Поселенные:</router-link> {{poselenieCount}}</li>
                                              <li><router-link to="/stat/users?noposelenie=true">Не поселенные: </router-link>{{usersNightCount -
                                                  poselenieCount}} </li>

                        </ul>
                    </div>
                    -->
                    <div class="col-md-4">
                        <ul>
                          <li><router-link to="/stat/discountMoney">Скидка в гривнах:</router-link> {{discountsCount}}</li>
                          <li><router-link to="/stat/prereg">Пред. регистрация:</router-link> {{preregCount}}</li>
                            <li><router-link to="/stat/showLated">Зарег.не прибывшие:</router-link> {{lateCount}} </li>
                            <li><router-link to="/stat/showBorg">Должники:</router-link> {{borgCount}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <daily-people class="col-md-10 offset-md-1"></daily-people>

        </div>
    </div>
</template>

<script>

    import daily from './daily';

  export default {
    data: function () {
      return {
        now: null,
        countryCount: 0,
        cityCount: 0,
        churchCount: 0,
        peopleCount: 0,
        pastorCount: 0,
        commandaCount: 0,
        discountsCount: 0,
        poselenieCount: 0,
        usersNightCount: 0,
        preregCount: 0,
        preregNotArrivedCount: 0,
        lateCount: 0,
        borgCount: 0,
        expressPercent: 0,
        expressCount: 0
      }
    },

    mounted() {
        this.load();
    },
    methods: {
      async load() {
        this.$loading(true);
        this.now = new Date();
        const { body } = await this.getData(`/admin/index`);
        const {
            countryCount, cityCount, churchCount, peopleCount, pastorCount,
            commandaCount, discountsCount, poselenieCount, usersNightCount, preregCount, preregNotArrivedCount,
            lateCount, borgCount, expressPercent, expressCount
          } = body;
        console.log(body);
        this.$loading(false);
        this.countryCount = countryCount;
        this.cityCount = cityCount;
        this.churchCount = churchCount;
        this.peopleCount = peopleCount;
        this.pastorCount = pastorCount;
        this.commandaCount = commandaCount;
        this.discountsCount = discountsCount;
        this.poselenieCount = poselenieCount;
        this.usersNightCount = usersNightCount;
        this.preregCount = preregCount;
        this.preregNotArrivedCount = preregNotArrivedCount;
        this.lateCount = lateCount;
        this.borgCount = borgCount;
        this.expressPercent = expressPercent;
        this.expressCount = expressCount;
      }
    },
    components: {
        DailyPeople: daily
    }

  }
</script>

import { render, staticRenderFns } from "./accommodataions.vue?vue&type=template&id=54df9b43&scoped=true&"
import script from "./accommodataions.vue?vue&type=script&lang=js&"
export * from "./accommodataions.vue?vue&type=script&lang=js&"
import style0 from "./accommodataions.vue?vue&type=style&index=0&id=54df9b43&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54df9b43",
  null
  
)

export default component.exports
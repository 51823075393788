<template>
    <div  class="row">
        <div class="col-md-8 offset-md-2">
            <h4>Движение средств</h4>
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>№</th>
                    <th>ID</th>
                    <th>Дата</th>
                    <th>Кто</th>
                    <th>Кому</th>
                    <th>Сумма</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="(item, index) in data"
                        v-bind:data-uid="item.uid"
                        v-bind:key="item.uid"
                >
                    <td>{{index+1}}</td>
                    <td>{{item.id}}</td>
                    <td>{{$moment(item.dt).format('DD.MM.YYYY HH:mm')}}</td>
                    <td>{{item.src}}</td>
                    <td>{{item.dst}}</td>
                    <td>{{item.money}}</td>
                </tr>
                </tbody>

            </table>
        </div>

    </div>

</template>

<script>
    export default {
      data: function() {
        return {
          data: []
        }
      },
      mounted: async function () {
        const { body } = await this.getData(`/admin/showMoneyTransfer`);
        this.data = body;
      }
    }

</script>

<template>
    <div class="row">
        <h4 class="col-11 offset-1">Плановые возвраты приемников</h4>
        <div class="col-10 offset-1">
            <table class="table">
                <thead>
                    <tr>
                        <th v-for="dayKey in (Object.keys(days))" :key="dayKey">{{dayKey}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td v-for="dayKey in (Object.keys(days))" :key="dayKey">
                            {{days[dayKey].map(item => item.nn).join(', ')}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
  export default {
    name: 'willReturn',
    data: function () {
        return {
          days: {}
        }
    },
    async mounted() {
      const { body: issued } = await this.getData('/interpreters/issued');
      const { body: { stdate, dayCount } } = await this.getData('/stat/retrts/dayCount');
      for (let cc = 1; cc <= dayCount; cc++) {
        this.days[this.$moment(stdate).add(cc - 1, 'days').format('DD.MM.YYYY')] = issued.filter(item => {
          const lastInterpreterDay = Number.parseInt(item.interpreters.split(/;/).pop());
          return lastInterpreterDay === cc;
        });
      }
      this.$forceUpdate();
    }
  }
</script>

<style scoped lang="scss">
    th, td{
        text-align: center;
        border: 1px #ccc dotted;
    }

</style>

<template>
    <div class="row">
        <div class="col-md-4 offset-md-1">
            <h4>{{title}}</h4>
        </div>
        <div class="col-md-3 offset-md-2 form-check" v-if="!this.noarrive">
            <input type="checkbox" id="onlyArrived" class="form-check-input" v-model="arrived" >
            <label for="onlyArrived" class="form-check-label">Только прибывшие</label>
        </div>
        <div class="col-md-10 offset-md-1">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>№</th>
                    <th>UID</th>
                    <th>Ф.И.О.</th>
                    <th>Страна</th>
                    <th>Город</th>
                    <th>Церковь</th>
                    <th>Служение</th>
                    <th>Сумма</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="(item, index) in users"
                        v-bind:data-uid="item.uid"
                        @click="go"
                        class="hover"
                        v-bind:key="item.uid"
                >
                    <td>{{index+1}}</td>
                    <td>{{item.uid}}</td>
                    <td>{{item.fullname}}</td>
                    <td>{{item.country}}</td>
                    <td>{{item.city}}</td>
                    <td>{{item.church}}</td>
                    <td>{{item.ministry}}</td>
                    <td>{{item.money>0?item.money:'-'}}</td>
                </tr>
                </tbody>

            </table>
        </div>
    </div>
</template>

<script>
    export default {
      data: function() {
        return {
          data: [],
          title: '',
          arrived: true,
          noarrive: false
        }
      },
      methods: {
        go(event) {
          const uid = event.target.closest('tr').getAttribute('data-uid');
          this.$router.push(`/reg/${uid}`);
        }
      },
      computed: {
        users() {
          let arr = this.data;
          if (this.arrived) {
            arr = arr.filter(item => item.dt_arrived);
          }
          return arr;
        }
      },

      async mounted() {
        const { body } = await this.getData(`${this.$route.fullPath}`);
        console.log(body);
        this.data = body.data;
        this.title = body.title;
        this.noarrive = body.noarrive || false;
        if (body.hasOwnProperty('arrived')) {
          this.arrived = body.arrived;
        }
      }
    }
</script>

<template>
    <div class="row">
        <div class="col-md-8 offset-md-2 row">
            <div class="col-10 offset-1">
                <h4>Предварительная регистрация</h4>
            </div>

            <div class="col-5">
                <label class="label">Страны</label>
                <v-select
                        v-model="selectedCountries"
                        multiple :options="countries" @input="countryChange"></v-select>
            </div>
        </div>
        <div class="col-md-10 offset-md-1" style="padding-top: 10px">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>№</th>
                    <th>UID</th>
                    <th>Ф.И.О.</th>
                    <th>Страна</th>
                    <th>Город</th>
                    <th>Ожидается<br>прибытие</th>
                    <th>Прибытие</th>
                    <th>Оплачено</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="(item, index) in users"
                        v-bind:data-uid="item.uid"
                        class="hover"
                        v-bind:key="item.uid"
                >
                    <td>{{index+1}}</td>
                    <td><a target="_blank" :href="'/reg/'+item.uid">{{item.uid}}</a></td>
                    <td>{{item.fullname}}</td>
                    <td>{{item.country}}</td>
                    <td>{{item.city}}</td>
                    <td>{{$moment(item.stDay).format('DD.MM.YYYY')}}</td>
                    <td>{{item.dt_arrived? $moment(item.dt_arrived).format('DD.MM.YYYY') :'-'}}</td>
                    <td>{{item.money!=0?item.money:'-'}}</td>
                </tr>
                </tbody>
                <tfoot style="border-top:1px dotted ">
                    <td colspan="7">Итого</td>
                    <td>{{total}}</td>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
  import vSelect from 'vue-select';
  export default {
    name: 'prereg',
    data: function () {
      return {
        countries: [],
        selectedCountries: [],
        users: [],
        total: 0
      }
    },
    async mounted() {
      const { body } = await this.getData(`/stat/preregCountries`);
      this.countries = body.map(item => {
        return {
          label: item.country,
          code: item.id
        }
      });
      await this.countryChange();
    },
    methods: {
      async countryChange() {
        const { body } = await this.getData(`/stat/preregUsers?countries=${this.selectedCountries.map(item => item.code).join(',')}`);
        this.users = body;
        this.total = 0;
        Array.from(body).forEach(item => this.total += Number.parseFloat(item.money));
      }
    },
    components: {
      vSelect
    }
  }
</script>

<style scoped>

</style>
